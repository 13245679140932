// LIBS
import React, {useEffect, useMemo, useState} from "react";
import {Avatar,Select, Table, Tooltip,} from "antd";
import {useTranslation} from "react-i18next";
import horseColor from "../classes/horseColor";
import competitor from "../services/competitor.service";
import fusion from "../services/fusion.service";
import {useSelectedRaceContext, useTrackerSupervisionDataContext} from "../pages/privateMonitoring";
import {STARTER_STATUS_LIST, NON_STARTER_STATUS_LIST} from "../enums/startStatus";
import event from "../services/event.service";

const {Option} = Select;

export const MonitoringDataList = React.forwardRef(() => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [competitors, setCompetitors] = useState([]);
    const trackerSupervisionData = useTrackerSupervisionDataContext();
    const {selectedRace} = useSelectedRaceContext();
    const [fusOptions, setFusOptions] = useState([]);

    const getFusionList = () => {
        setFusOptions([]);
        fusion.list()
            .then((r) => {
                if (r && r.data) {
                    setFusOptions(r.data);
                }
                getCompetitors();
            });
    }

    // getCompetitors ------------------------------------------------------
    const getCompetitors = () => {
        setCompetitors([]);
        if (selectedRace) {
            competitor.getListByRace(selectedRace).then(res => {
                if (res && res.data && res.data.length) {
                    setCompetitors(res.data);
                }
            })
        }
    };

    const cellStatusHandler = (v, updatedCompetitor) => {
        if (updatedCompetitor) {
            updatedCompetitor.status = v;
            const data = {
                type: 'COMPETITOR_STATUS_UPDATE',
                competitorNumber: updatedCompetitor.number,
                updatedCompetitorStatus : v,
                raceUuid: selectedRace}
            event.save(data).then();
        }
    }

    const cellFusionHandler = (v, updatedCompetitor) => {
        if (updatedCompetitor) {
            updatedCompetitor.fusionId = v;
            const data = {
                type: 'COMPETITOR_FUSION_UPDATE',
                competitorNumber: updatedCompetitor.number,
                updatedCompetitorFusion : v,
                raceUuid: selectedRace}
            event.save(data).then();
        }
    }

    const setTrackersDataByCompetitor = (trackersData) => {
        if (!trackersData || !trackersData.length) {
            return;
        }
        if (competitors) {
            let competitorsCopy = competitors.slice();
            for (let c = 0; c < trackersData.length; c++) {
                let obj = trackersData[c];
                if (!obj) continue;
                const competitor = competitorsCopy.find(competitor => competitor.fusionId === obj.fusionId);
                if (competitor) {
                    competitor.status = obj.status;
                    competitor.trackers = obj.trackers;
                    competitor.raceRank = obj.rank;
                    competitor.json = obj.engineStatus;
                    competitor.latency = obj.latency;
                    // competitor.hr_value = obj.trackers
                    //     .filter(t => t.hr_value !== null)
                    //     .filter(t => t.hr_value !== '-')
                    //     .filter(t => t.hr_value !== 0)
                    //     .map(t => t.hr_value)
                    //     .pop();
                }
            }
            setCompetitors(competitorsCopy);
        }
    }

    // useEffect ------------------------------------------------------
    useEffect(() => {
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // on race selection, load data from db
    useEffect(() => {
        if (selectedRace) {
            getFusionList()
        } else {
            setCompetitors([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRace]);

    useEffect(() => {
        if (trackerSupervisionData) {
            setTrackersDataByCompetitor(trackerSupervisionData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackerSupervisionData]);

    // columns definition ---------------------------------------------
    const columns = [
        {
            title: t('monitoring.competitorNumber'),
            dataIndex: "number",
            className: 'sec-col-num',
            ellipsis: true,
            render: (value) => {
                let color = horseColor(value);
                return (
                    <Avatar
                        style={{
                            backgroundColor: `#${color.color}`,
                            color: `#${color.font}`,
                            fontSize: `14px`,
                            border: `1px solid #${color.border}`,
                            verticalAlign: 'middle'
                        }}
                        size={25}>{value}</Avatar>
                )
            },
        },
        {
            title: t('monitoring.status'),
            dataIndex: "status",
            ellipsis: true,
            render: (value, record) => {
                return (
                    <Select value={value} onChange={v => cellStatusHandler(v, record)}>
                        {
                            STARTER_STATUS_LIST.map(status => {
                                return <Option
                                    key={`${record.uuid}-${status}`}
                                    value={status}>
                                    <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                                </Option>
                            })
                        }
                        {
                            NON_STARTER_STATUS_LIST.map(status => {
                                return <Option
                                    key={`${record.uuid}-${status}`}
                                    value={status}>
                                    <Tooltip title={t(`competitor.status.${status}`)}>{status}</Tooltip>
                                </Option>
                            })
                        }
                    </Select>
                )
            }
        },
        {
            title: <div className="th_fus">
                <span>{t('monitoring.fusion')}</span>
            </div>,
            dataIndex: "fusionId",
            ellipsis: true,
            render: (value, record) => {
                return (
                    <Select value={value} onChange={v => cellFusionHandler(v, record)}>
                        <Option key={`null_${record.uuid}`} value={`null`}>-</Option>
                        {fusOptions.map(opt => {
                            return <Option
                                key={`${opt.fusionId}`}
                                value={opt.fusionId}>
                                {opt.fusionId}
                            </Option>
                        })}
                    </Select>
                )
            }
        },
        {
            title: t('monitoring.trackers'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-id`}>{tracker.trackerId}</span>
                        })}
                    </div>
                } else {
                    return <div className="capt_cell"><span>{t('monitoring.empty')}</span></div>
                }
            }
        },
        {
            title: t('monitoring.isInCharge'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-is-in-charge`}>{tracker.isInCharge}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.networkProvider'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-network-provider`}>{tracker.networkProvider}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.networkType'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-network-type`}>{tracker.networkType}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.battery'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-bat`}>{tracker.battery}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.lt'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                                return <span className={`${tracker.status}`}
                                             key={`${tracker.trackerId}-${i}-lt`}>{tracker.lt}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.mode'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.mode}`}
                                             key={`${tracker.trackerId}-${i}-mode`}>{t(`monitoring.${tracker.mode}`)}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.latency'),
            dataIndex: "latency",
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"> <span>{value} ms</span></div>
            }
        },
        {
            title: t('monitoring.json'),
            dataIndex: "json",
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"><span className={`${value}`}>{t(`monitoring.${value}`)}</span></div>
            }
        },
        {
            title: t('monitoring.rank'),
            dataIndex: "raceRank",
            key: "raceRank",
            ellipsis: true,
            render: (value) => {
                if (!value) {
                    return <div className="capt_cell"><span> </span></div>
                }
                return <div className="capt_cell"><span>{value}</span></div>
            }
        },
        {
            title: t('monitoring.hr'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.hr_value}`}
                                             key={`${tracker.trackerId}-${i}-hr_value`}>{tracker.hr_value}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: t('monitoring.cps'),
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        {trackers.map((tracker, i) => {
                            return <span className={`${tracker.status} ${tracker.cps_value}`}
                                             key={`${tracker.trackerId}-${i}-cps_value`}>{tracker.cps_value}</span>
                            }
                        )}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
        {
            title: 'DpDp',
            dataIndex: "trackers",
            ellipsis: true,
            render: (trackers) => {
                if (trackers && trackers.length > 0) {
                    return <div className="capt_cell">
                        { trackers.map((tracker, i) => {
                            return <span className={`${tracker.status}`}
                                         key={`${tracker.trackerId}-${i}-dpdp`}>{Math.floor(tracker.DpDp)}</span>
                        })}
                    </div>
                }
                return <div className="capt_cell"><span> </span></div>
            }
        },
    ];

    const table = useMemo(() => {
        return <div className="pdl_wrapper">
                <Table
                    bordered
                    columns={columns}
                    dataSource={competitors}
                    loading={loading}
                    rowKey="uuid"
                    className="pilotage_list"
                    rowClassName={(record) => {
                        if (NON_STARTER_STATUS_LIST.includes(record.status)) {
                            return 'editable-row disabled';
                        }
                        return 'editable-row';
                    }}
                    pagination={false}
                />
            </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitors])

    return <> {table} </>
})

export default MonitoringDataList;